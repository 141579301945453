.App {
  background: #fff9e3;
  color: #2c1218;
}

.anim-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    background: #fff9e3;
}

.anim-background span {
  width: 20vmin;
  height: 20vmin;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.anim-background span:nth-child(0) {
  top: 72%;
  left: 84%;
  animation-duration: 51s;
  animation-delay: -26s;
  transform-origin: 8vw -21vh;
}

.anim-background span:nth-child(1) {
  top: 25%;
  left: 22%;
  animation-duration: 32s;
  animation-delay: -5s;
  transform-origin: -10vw -13vh;
}
.anim-background span:nth-child(2) {
  top: 26%;
  left: 11%;
  animation-duration: 29s;
  animation-delay: -22s;
  transform-origin: -15vw -10vh;
}

.anim-background span:nth-child(3) {
  top: 61%;
  left: 88%;
  animation-duration: 23s;
  animation-delay: -25s;
  transform-origin: 15vw -18vh;
}

.anim-background span:nth-child(4) {
  top: 87%;
  left: 33%;
  animation-duration: 22s;
  animation-delay: -49s;
  transform-origin: 2vw 19vh;
}
.anim-background span:nth-child(5) {
  top: 33%;
  left: 78%;
  animation-duration: 13s;
  animation-delay: -25s;
  transform-origin: -8vw -4vh;
}
.anim-background span:nth-child(6) {
  top: 41%;
  left: 85%;
  animation-duration: 11s;
  animation-delay: -19s;
  transform-origin: 10vw -1vh;
}
.anim-background span:nth-child(7) {
  top: 14%;
  left: 52%;
  animation-duration: 44s;
  animation-delay: -24s;
  transform-origin: 2vw -18vh;
}
.anim-background span:nth-child(8) {
  top: 31%;
  left: 45%;
  animation-duration: 52s;
  animation-delay: -39s;
  transform-origin: 13vw -5vh;
}
.anim-background span:nth-child(9) {
  top: 82%;
  left: 74%;
  animation-duration: 37s;
  animation-delay: -14s;
  transform-origin: 1vw -2vh;
}
.anim-background span:nth-child(10) {
  top: 55%;
  left: 90%;
  animation-duration: 32s;
  animation-delay: -46s;
  transform-origin: -3vw 18vh;
}
.anim-background span:nth-child(11) {
  top: 48%;
  left: 98%;
  animation-duration: 47s;
  animation-delay: -38s;
  transform-origin: -19vw 5vh;
}
.anim-background span:nth-child(12) {
  top: 13%;
  left: 11%;
  animation-duration: 18s;
  animation-delay: -10s;
  transform-origin: 18vw 2vh;
}
.anim-background span:nth-child(13) {
  top: 34%;
  left: 37%;
  animation-duration: 26s;
  animation-delay: -41s;
  transform-origin: 6vw 16vh;
}
.anim-background span:nth-child(14) {
  top: 74%;
  left: 86%;
  animation-duration: 25s;
  animation-delay: -48s;
  transform-origin: 17vw -8vh;
}
.anim-background span:nth-child(15) {
  top: 3%;
  left: 100%;
  animation-duration: 25s;
  animation-delay: -27s;
  transform-origin: -16vw 9vh;
}
.anim-background span:nth-child(16) {
  top: 20%;
  left: 64%;
  animation-duration: 49s;
  animation-delay: -11s;
  transform-origin: 9vw -14vh;
}
.anim-background span:nth-child(17) {
  top: 41%;
  left: 6%;
  animation-duration: 17s;
  animation-delay: -1s;
  transform-origin: 17vw 12vh;
}
.anim-background span:nth-child(18) {
  top: 23%;
  left: 57%;
  animation-duration: 49s;
  animation-delay: -17s;
  transform-origin: 11vw -4vh;
}
.anim-background span:nth-child(19) {
  top: 15%;
  left: 18%;
  animation-duration: 54s;
  animation-delay: -22s;
  transform-origin: 13vw -6vh;
}


@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}